import React from 'react'
import {motion} from 'framer-motion'
import { makeStyles } from '@material-ui/core/styles';
import {typography} from '@src/theme/theme'
import { hBox,vBox } from "gx-web-ui/utils/flex";
import ButtonBase from '@material-ui/core/ButtonBase'
// import cx from 'classnames'

const LandingPageHeaderTab = props=>{
    const {label,onClick,className,isSelected=false,style, isScrollTop} = props
    const styles = useStyles()
    //const theme = useTheme()
    return <ButtonBase onClick={onClick} className={className} disableRipple>
        <motion.div className={styles.base} style={style} whileHover="hover" initial="rest">
            <div 
                // variants={{
                //     selected:{color: isScrollTop ? 'rgb(0,0,0,0.4)' : 'white'},
                //     unselected:{color:isScrollTop ? 'rgb(0,0,0,0.4)' : 'white'}
                // }}
                // initial={isSelected?'selected':'unselected'}
                // animate={isSelected?'selected':'unselected'}
                style={{color: isScrollTop ? 'rgb(0,0,0,0.4)' : 'white'}}
                className={styles.label}>
                {label}
            </div>
            <motion.div 
                
                variants={{
                    hover:{opacity : 1},
                    rest:{opacity : 0}
                }}
                // initial={{}}
                // whileHover={{opacity : 1}}
                // initial={isSelected?'selected':'unselected'}
                // animate={isSelected?'selected':'unselected'}
                className={styles.bar}
            />
        </motion.div >
    </ButtonBase>
}

const useStyles = makeStyles(theme=>({
    base:{
        ...vBox('center','center'),
        position:'relative'
    },
    bar:{
        height:3,
        backgroundColor:theme.palette.clientPrimary.main,
        width:20,
        position:'absolute',
        transform:'translateY(15px)'
    },
    label:{
        ...typography.subtitle2,
        fontSize : 16
    },
}))
export default LandingPageHeaderTab
