import React from 'react'
import { Dropdown, Card, Typography } from "antd"

import TeamlinkQR from 'assets/images/teamlinkQR.png';
import TeamlinkLogo from 'assets/images/logo.png';

const styles = {
    bodyStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '32px',
        flexDirection: 'column'
    },
    downloadText: {
        fontSize: 16,
        marginRight: 46
    },
    title: {
        marginBottom: 0,
    },
    logo: {
        height: 84,
        width: 84,
    },
    slogan: {
        fontSize: 12,
    },
    img: {
        height: 120,
        width: 120,
    }
}

const QRMenu = (props) => {
    const { textColor } = props

    const card = (
        <Card bodyStyle={styles.bodyStyle}>
            <img src={TeamlinkLogo} style={styles.logo} />
            <Typography.Title level={5} style={styles.title}>Teamlink</Typography.Title>
            <Typography.Paragraph type="secondary" style={styles.slogan}>Properties in your hand</Typography.Paragraph>
            <img src={TeamlinkQR} style={styles.img} />
        </Card>
    )

    return (
        <Dropdown overlay={card}>
            <Typography.Link style={{ ...styles.downloadText, color: textColor }} > Download APP</Typography.Link>
        </Dropdown >
    )
}

export default QRMenu

