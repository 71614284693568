import React from "react"
import Container from "../components/container"
import SEO from "../components/seo"
import withRoot from '../withRoot'
import _ from 'lodash'


const NotFoundPage = () => {
  return <Container defKey="2">
    <SEO title="404: Not found" />
    <div style={{minHeight:'100vh',marginTop:100,padding:16}}>
        <h1>Property or Project NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Container>
}


export default withRoot(NotFoundPage)
